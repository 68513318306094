import client from '../client'

export default function postChat({
  modified,
  params,
}: {
  modified: boolean
  params: Record<string, unknown>
}) {
  return client.post(
    'api/company/chat/' + (modified ? 'popup' : 'message') + '.json',
    params,
  )
}

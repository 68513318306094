import { Affiliate } from '../../types/Affiliate'
import client from '../client'

export default function logCustomActivity({
  visitorToken,
  affiliate,
}: {
  visitorToken: string
  affiliate: Affiliate
}) {
  return client.post('custom_activities', {
    custom_activity: {
      name: 'banner',
      visitor: visitorToken,
      affiliate: affiliate?.id,

      parameters: {},
    },
  })
}

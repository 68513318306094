import { z } from 'zod'
import { countrySchema } from './Country'
import { variantCountrySchema } from './VariantCountry'

const productSchema = z.object({
  id: z.number(),
  title: z.string(),
  price: z.string(),
  images: z.array(z.any()),
  countries: z.array(countrySchema),
  stripped_description: z.string(),
  option_values: z.any(),
  average_rating: z.number(),
  'hide_rating?': z.boolean(),
  'hide_reviews?': z.boolean(),
  display_price: z.string(),
  cv: z.number(),
  description: z.string(),
  image_url: z.string(),
  active: z.boolean(),
  short_description: z.string().nullable(),
  variants: z.array(
    z.object({
      id: z.number(),
      title: z.string(),
      image_url: z.string().nullable(),
      buyable: z.boolean(),
      option_value_ids: z.array(z.any()),
      allow_subscription: z.boolean(),
      subscription_only: z.boolean(),
      shipping_included_in_price: z.boolean(),
      option_values: z.array(z.any()),
      variant_countries: z.array(variantCountrySchema),
    }),
  ),
})

export type Product = z.infer<typeof productSchema>
export { productSchema }

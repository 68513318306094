import client from '../client'

export default function sendChatRequest(appSetting: any) {
  return client.post(`api/company/chat.json?q=${+new Date()}`, appSetting, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
}

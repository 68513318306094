import { z } from 'zod'

const variantSchema = z.object({
  id: z.number(),
  product_id: z.number(),
  shopify_id: z.string().nullable(),
  title: z.string().nullable(),
  sku: z.string(),
  position: z.number(),
  inventory_policy: z.string(),
  taxable: z.boolean(),
  inventory_quantity: z.number(),
  created_at: z.string(),
  updated_at: z.string(),
  external_id: z.string().nullable(),
  variant_country_id: z.any().nullable(),
  image_url: z.string().nullable(),
  image_path: z.string().nullable(),
  is_master: z.boolean(),
  discarded_at: z.string().nullable(),
  price: z.string().nullable(),
  weight: z.string().nullable(),
  size: z.string().nullable(),
  physical: z.boolean().nullable(),
  unit_of_weight: z.string().nullable(),
  height: z.string().nullable(),
  width: z.string().nullable(),
  length: z.string().nullable(),
  unit_of_size: z.string().nullable(),
  hs_code: z.string().nullable(),
  country_of_origin: z.string().nullable(),
  customs_information_shipping: z.boolean().nullable(),
  active: z.boolean(),
  bar_code: z.string().nullable(),
  track_quantity: z.boolean(),
  keep_selling: z.boolean(),
  option_attrs: z.array(z.any()),
  allow_subscription: z.boolean(),
  subscription_only: z.boolean(),
  limit_subscription: z.boolean(),
  subscription_max_qty: z.number().nullable(),
  shipping_included_in_price: z.boolean(),
  subscription_interval: z.number(),
})

export type Variant = z.infer<typeof variantSchema>
export { variantSchema }

import { Affiliate } from '../../types/Affiliate'
import { Visitor } from '../../types/Visitor'
import client from '../client'

export default function logVisitorView(visitor: Visitor, affiliate: Affiliate) {
  return client.post('custom_activities', {
    custom_activity: {
      name: 'product-detail-page',
      visitor,
      affiliate: affiliate.id,
      parameters: {},
    },
  })
}

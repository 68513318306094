import getCart from '../../../api/cart/getCart'
import getCartByToken from '../../../api/cart/getCartByToken'
import postCartItems from '../../../api/cart/postCartItems'
import { CartResponse } from '../../../types/CartResponse'

export const cartItemApiRoute = ({
  variantId,
  productIds,
  cartToken,
  cartId,
  visitor,
  cart_type,
  affiliate,
  subscription,
  quantity,
  country_iso,
  enrollmentPackId,
  language_iso,
}: {
  variantId?: number
  productIds?: number[]
  cartToken: string
  cartId: string
  visitor: string
  cart_type?: string
  affiliate?: string | number
  subscription?: boolean
  quantity?: string | number
  country_iso: string
  enrollmentPackId?: number
  language_iso: string
}): Promise<CartResponse | undefined> => {
  const isEnrollment = cart_type === 'enrollment'

  if (cartToken && !productIds) {
    return getCartByToken(cartToken, visitor)
  }

  let params = {
    cart_item: {
      quantity: quantity || 1,
      product_id: productIds,
      product_ids: productIds,
      variant_id: variantId,
      subscription: subscription || false,
    },
    ...(isEnrollment ? { enrollment_pack_id: enrollmentPackId } : {}),
    visitor_token: visitor,
    ...(country_iso ? { country_iso } : {}),
    cart_token: cartToken,
    cart_type,
    affiliate,
    language_iso,
  }

  if (productIds) {
    return postCartItems(params)
  } else if (cartId) {
    return getCart(cartId, {}, params)
  }
  return new Promise<undefined>((resolve) => resolve(undefined))
}

import { z } from 'zod'
import { cartItemSchema } from './CartItem'
import { countrySchema } from './Country'
import { languageSchema } from './Language'
import { visitorSchema } from './Visitor'

const cartResponseSchema = z.object({
  id: z.number(),
  token: z.string(),
  visitor_id: z.number(),
  country: countrySchema,
  type: z.string(),
  hold_card_thirty_days: z.boolean().nullable(),
  repeat_order: z.boolean().nullable(),
  order_number: z.string().nullable(),
  currency_code: z.string(),
  currency_symbol: z.string(),
  display_price: z.string(),
  email: z.string().nullable(),
  amount_total: z.string(),
  sub_total: z.string(),
  tax_total: z.string(),
  shipping_total: z.string(),
  cv_total: z.number(),
  fulfillment: z.any().nullable(),
  company_coupon_or_promo_code: z.boolean(),
  checkout_success_url: z.string().nullable(),
  dynamic_charge: z.any(),
  company_specific_languages: z.array(languageSchema),
  messages: z.any(),
  buyer: z.object({
    token: z.string(),
    email: z.string().nullable(),
    full_name: z.string().nullable(),
    is_enrolled: z.boolean(),
  }),
  applied_discounts: z.object({
    discount_codes: z.array(z.string()),
    amount: z.string(),
  }),
  cart_items: z.array(cartItemSchema),
  manual_tax: z.boolean(),
  visitor: visitorSchema,
  manual_shipping: z.boolean(),
  wallets: z.array(z.any()),
  'webhook_enabled?': z.boolean(),
  membership_fee: z.number(),
  enrollment_redirect_url: z.string().nullable(),
  message: z.string().nullable(),
  card: z.any().nullable(),
  ship_to: z
    .object({
      id: z.number(),
      name: z.string(),
      address1: z.string().nullable(),
      address2: z.string().nullable(),
      address3: z.string().nullable(),
      city: z.string().nullable(),
      state: z.string().nullable(),
      postal_code: z.string().nullable(),
      country_code: z.string(),
      country: countrySchema.nullable(),
      default: z.boolean(),
    })
    .nullable(),
  shipping_options: z.array(z.any()),
})

export type CartResponse = z.infer<typeof cartResponseSchema>
export { cartResponseSchema }

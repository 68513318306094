import { CartResponse, cartResponseSchema } from '../../types/CartResponse'
import client from '../client'

export default async function getCart(
  cartId: string,
  { visitor }: { visitor?: string },
  params?: any,
): Promise<CartResponse> {
  let response
  try {
    response = (
      await client.get(
        `api/v1/carts/${cartId}${visitor ? `?visitor=${visitor}` : ''}`,
        { params },
      )
    ).data

    return cartResponseSchema.parse(response)
  } catch (error) {
    if (process.env.NODE_ENV === 'development') {
      console.error(error)
    }
    return response as CartResponse
  }
}

import { z } from 'zod'

const languageSchema = z.object({
  id: z.number(),
  name: z.number(),
  iso: z.number(),
  active: z.boolean().nullable(),
  default: z.boolean().nullable(),
  created_at: z.string(),
  updated_at: z.string(),
})

export type Language = z.infer<typeof languageSchema>
export { languageSchema }

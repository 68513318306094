import { z } from 'zod'

const countrySchema = z.object({
  id: z.number(),
  iso_name: z.string(),
  iso: z.string(),
  iso3: z.string(),
  name: z.string(),
  numcode: z.number(),
  active: z.boolean(),
  currency_code: z.string(),
  currency_symbol: z.string(),
  separator: z.string(),
  delimiter: z.string(),
  address3: z.boolean(),
  state: z.boolean(),
  passport: z.boolean(),
  itn: z.boolean(),
  tax_rate: z.number(),
  default: z.boolean(),
  created_at: z.string(),
  updated_at: z.string(),
  geo_entity_id: z.number().nullable(),
})

export type Country = z.infer<typeof countrySchema>
export { countrySchema }

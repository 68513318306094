import { z } from 'zod'
import { productSchema } from './Product'
import { variantSchema } from './Variant'
import { variantCountrySchema } from './VariantCountry'

const cartItemSchema = z.object({
  id: z.number(),
  quantity: z.number(),
  variant: variantSchema,
  ordered_variant: z.array(z.any()),
  price: z.string(),
  subscription: z.boolean(),
  subscription_start: z.string().nullable(),
  message: z.string().nullable(),
  product: productSchema,
  save_amount: z.number(),
  free_item: z.boolean(),
  variant_country: variantCountrySchema,
})

export type CartItem = z.infer<typeof cartItemSchema>
export { cartItemSchema }

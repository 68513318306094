import { CartResponse, cartResponseSchema } from '../../types/CartResponse'
import client from '../client'

export default async function getCartByToken(
  cartToken: string,
  visitor: string,
): Promise<CartResponse> {
  let response
  try {
    response = (
      await client.get(`/api/v1/carts/${cartToken}/cart_by_token`, {
        params: { visitor_token: visitor },
      })
    ).data

    return cartResponseSchema.parse(response)
  } catch (error) {
    if (process.env.NODE_ENV === 'development') {
      console.error(error)
    }
    return response as CartResponse
  }
}

import { CartResponse, cartResponseSchema } from '../../types/CartResponse'
import client from '../client'

export default async function postCartItems(
  params: any,
): Promise<CartResponse> {
  let response
  try {
    response = (await client.post('/api/v1/cart_items', params)).data

    return cartResponseSchema.parse(response)
  } catch (error) {
    if (process.env.NODE_ENV === 'development') {
      console.error(error)
    }
    return response as CartResponse
  }
}

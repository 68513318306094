import client from '../client'

export default function confirmVisitor(visitorToken: string) {
  return client.post(
    'api/confirm-visitor',
    {
      token: visitorToken,
    },
    { timeout: 30000 },
  )
}

import { z } from 'zod'

const variantCountrySchema = z.object({
  id: z.number(),
  variant_id: z.number(),
  price: z.string(),
  cv: z.number(),
  active: z.boolean(),
  created_at: z.string(),
  updated_at: z.string(),
  country_id: z.number(),
  shipping: z.string(),
  shipping_time: z.string().nullable(),
  tax: z.string(),
  currency_code: z.string(),
  qv: z.number(),
  subscription_price: z.string(),
  wholesale: z.string(),
  wholesale_subscription_price: z.string(),
  cost_of_goods_sold: z.string(),
  external_id: z.string().nullable(),
  compare_price: z.string().nullable(),
  pc_cv: z.number().nullable(),
  pc_qv: z.number().nullable(),
})

export type VariantCountry = z.infer<typeof variantCountrySchema>
export { variantCountrySchema }
